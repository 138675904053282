<template>
  <div class="home">
    <input type="text" v-model="start" />
    <!-- <gmap-autocomplete class="introInput" @place_changed="setPlace">
      <template v-slot:input="slotProps">
        <v-text-field
          outlined
          prepend-inner-icon="place"
          placeholder="Location Of Event"
          ref="input"
          v-on:listeners="slotProps.listeners"
          v-on:attrs="slotProps.attrs"
        >
        </v-text-field>
      </template>
    </gmap-autocomplete> -->
    <!-- <select v-model="start">
      <option value="-6.204306204675345, 106.65342937988443">Rumah</option>
      <option value="st louis, mo">St Louis</option>
      <option value="joplin, mo">Joplin, MO</option>
    </select>
    <b>End:</b> -->
    <select v-model="end">
      <option value="chicago, il">Chicago</option>
      <option value="st louis, mo">St Louis</option>
      <option value="joplin, mo">Joplin, MO</option>
      <option value="-6.179166594083949, 106.64301137222667">Balekota</option>
    </select>
    <GmapMap :zoom="7" :center="{ lat: 41.85, lng: -87.65 }">
      <DirectionsRenderer
        travelMode="DRIVING"
        :origin="origin"
        :destination="destination"
      />
    </GmapMap>
    <div id="directionsPanel" style="float:right;width:30%;height:100%"></div>
  </div>
</template>

<script>
import DirectionsRenderer from "@/components/DirectionsRenderer";

export default {
  components: {
    DirectionsRenderer,
  },
  data() {
    return {
      myLocation: null,
      start: null,
      end: null,
    };
  },
  mounted() {
    this.showLocation();
    window.setInterval(() => {
      this.showLocation();
    }, 1000);
  },
  computed: {
    origin() {
      if (!this.start) return null;
      return { query: this.start };
    },
    destination() {
      if (!this.end) return null;
      return { query: this.end };
    },
  },
  methods: {
    setPlace(place) {
      console.log(place);
      this.start = place.name;
    },
    showLocation() {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.myLocation = pos;
        this.start = pos.coords.latitude + "," + pos.coords.longitude;
        console.log(pos);
      });
    },
  },
};
</script>
<style>
.vue-map-container {
  height: 300px;
}
#directionsPanel {
  width: 100% !important;
}
</style>
